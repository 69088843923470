@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;1,100;1,300&display=swap");

/* Scrollbar style */
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8d8989;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8d8989;
}

/* data table styles */
.css-f3jnds-MuiDataGrid-columnHeaders {
  background-color: rgb(103, 116, 142);
}

.css-3ihp42-MuiDataGrid-root .MuiDataGrid-iconSeparator {
  color: transparent !important;
}

.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  color: white;
}

.MuiDataGrid-row {
  cursor: pointer;
}

/* search drop down */
#disable-close-on-select {
  color: whitesmoke;
}

/* preloader */
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* hide arrow in input number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* loading indicator */
.css-1yt7yx7-MuiLoadingButton-loadingIndicator {
  color: white !important;
}

/* stepper lable color */
.css-qivjh0-MuiStepLabel-label.Mui-completed {
  color: whitesmoke !important;
}

.css-qivjh0-MuiStepLabel-label.Mui-active {
  color: whitesmoke !important;
}

.css-16ubnlw-MuiStepLabel-labelContainer {
  color: whitesmoke !important;
}

/* material ui date picker css */
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  color: whitesmoke !important;
}

/* .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root .text_black {
  color: black !important;
} */
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: whitesmoke !important;
}
.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
  color: whitesmoke !important;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: black !important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: black !important;
}

/* sidebar css */
.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root {
  color: whitesmoke !important;
}
.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
  background-color: #111322 !important;
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  color: whitesmoke !important;
}

/* checkbox lable size modify */

/* @media screen and (max-width: 480px) {
  .css-ahj2mt-MuiTypography-root {
    font-size: 1rem !important;
  }
} */

/* bg of material sidebar in prod*/
.css-wf16b5 {
  background-color: rgb(20 23 40) !important;
}

/* date picker color in prod */
.css-slyssw {
  color: whitesmoke !important;
}
.css-u9osun {
  color: whitesmoke !important;
}

/* step lable color in prod */
.css-farnub.MuiStepLabel-alternativeLabel {
  color: whitesmoke !important;
}

.css-oarg8b-MuiAutocomplete-root .MuiAutocomplete-tag {
  color: whitesmoke !important;
}
